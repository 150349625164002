<template>
    <multiselect
        track-by="value"
        label="label"
        v-model="selected"
        :options="statusOptions"
        :allow-empty="false"
        :close-on-select="true"
        :option-height="40"
        :showLabels="false"
        :searchable="false"
        :multiple="false"
        placeholder="Selecione"
        @select="onSelect"
    >
        <template slot="caret">
            <div class="chevron">
                <ChevronDown />
            </div>
        </template>
        <template slot="singleLabel" slot-scope="{ option }">
            <div class="single-label" :class="{ [parseStatusClass(option.value)]: true }">
                {{ option.label }}
            </div>
        </template>
        <template slot="noOptions"> Nenhuma opção </template>
        <template slot="noResult"> Nenhum resultado </template>
    </multiselect>
</template>
<script>
    import { guideStatusOptions } from '@/modules/tiss/manageGuides/utils/statuses'
    export default {
        name: 'SelectItemSituation',
        props: {
            'value': String,
        },
        components: {
            ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
        },
        data() {
            return {
                selected: null,
                statusOptions: guideStatusOptions,
            }
        },
        mounted() {
            this.selected = this.statusOptions.find(option => option.value === this.value) || this.statusOptions[0]
        },
        methods: {
            onSelect(option) {
                this.$emit('input', option.value)
                this.$emit('change', option.value)
            },
            parseStatusClass(status) {
                const classes = {
                    'Em aberto': 'openned',
                    'Pago': 'paid-out',
                    'Pago parcialmente': 'partial',
                    'Glosado': 'gossed',
                }
                return classes[status]
            }
        },
        watch: {
            value(value) {
                this.selected = this.statusOptions.find(option => option.value === value) || this.statusOptions[0]
            }
        },
    }
</script>
<style lang="scss" scoped>
.single-label {
    white-space: nowrap;
    &.paid-out {
        color: var(--states-success);
    }
    &.partial {
        color: var(--orange);
    }
    &.gossed {
        color: var(--states-error);
    }
}
</style>